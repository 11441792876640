import { useState } from "react";
import { useRouter } from "next/router";

import Link from "next/link";
import Logo from "@components/layout/Logo";

import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

export default function NavBarMenu({ subTitle, title, items }) {
  const [navbar, setNavbar] = useState(false);
  const router = useRouter();

  const onToggleLanguageClick = (newLocale: string) => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };

  const datas = items.filter((item) => item.attributes.parent.data === null);

  const menus = [];
  for (let i = 0; i < datas.length; i++) {
    const childrens = [];
    if (datas[i].attributes.childrens.data.length) {
      for (let j = 0; j < datas[i].attributes.childrens.data.length; j++) {
        childrens.push({
          title: datas[i].attributes.childrens.data[j].attributes.name,
          itemId: datas[i].attributes.childrens.data[j].id,
        });
      }
    }
    menus.push({
      title: datas[i].attributes.name,
      itemId: datas[i].id,
      subNav: childrens,
    });
  }

  const handleMenuClick = (id) => {
    const menu = items.filter((item) => item.id === id)[0];
    if (menu.attributes?.childrens?.data.length) return;
    if (menu.attributes.isUrl) {
      openInNewTab(menu.attributes.link);
    } else router.push(`/${menu.attributes.link}`);
    setNavbar(false);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="w-full z-50">
      <nav className="w-full top-0 right-0 bg-[#096DD9] shadow">
        <div className="justify-between w-full px-4 mx-auto md:items-center md:flex md:px-8">
          <div className="w-full">
            <div className="flex items-center justify-between w-full py-3 md:py-5 md:block text-white">
              <Logo subTitle={subTitle} title={title} />
              <div className="space-x-3">
                <button
                  onClick={() => onToggleLanguageClick("mn")}
                  className="text-white text-white/[.80] text-xs w-fit"
                >
                  Mon
                </button>
                <button
                  onClick={() => onToggleLanguageClick("en")}
                  className="text-white text-white/[.80] text-xs w-fit"
                >
                  Eng
                </button>
              </div>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="absolute z-50 bg-white w-[300px] shadow-xl right-0">
            <div
              className={`pl-5 z-50 flex-1 justify-self-center pb-3 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <Navigation
                activeItemId="/management/members"
                onSelect={({ itemId }) => {
                  handleMenuClick(itemId);
                }}
                items={menus}
              />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
