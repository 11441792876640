import Link from "next/link";

export default function Logo(props: any) {
  const color = props.color === "white" ? "white" : "[#262626]";
  return (
    <Link href="/">
      <div className="flex flex-row items-center cursor-pointer">
        <img
          src="/logo.svg"
          width={`48px`}
          height={`48px`}
          alt="logo"
          className="rounded-full"
        />
        <div
          className={`text-sm ml-3 hidden lg:block xs:block leading-4 text-${color}`}
        >
          <div className="text-xs text-[#0254BC]">{props.subTitle}</div>
          <div className="text-[#0254BC] font-bold text-xs max-w-[200px]">
            {props.title}
          </div>
        </div>
      </div>
    </Link>
  );
}

Logo.defaultProps = {
  color: "#262626",
  title: "",
  subTitle: "",
};
