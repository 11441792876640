import React, { useState } from "react";

import Item from "./Item";

const Dropdown = ({ items }) => {
  const datas = items.filter((item) => item.attributes.parent.data === null);

  return (
    <div className="flex flex-row">
      {datas.map((item, ind) => {
        return <Item key={ind} data={item} />;
      })}
    </div>
  );
};

export default Dropdown;
