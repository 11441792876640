import router from "next/router";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

const DropdownItem = ({ data }) => {
  const router = useRouter();
  const [isShow, setShowHide] = useState(false);

  const handleClick = () => {
    setShowHide((prev) => !prev);
  };

  const handleClickMenuItem = () => {
    router.push("/about/9");
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert('You clicked outside of me!');
          // setCollapsed(false);
          setShowHide(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // const handleItemClick = () => {
  //   console.log(data.attributes);
  //   router.push(`${data.attributes.link}`);
  // };

  const MenuItem = ({ parent, own }) => {
    if (own.attributes.isUrl) {
      return (
        <a
          target="_blank"
          className="text-gray-600 block px-4 py-1 text-xs hover:text-blue-500"
          role="menuitem"
          id="menu-item-0"
          href={`${own.attributes.link}`}
        >
          {own.attributes.name}
        </a>
      );
    } else {
      return (
        <Link href={`/${own.attributes.link}`} key={own.id}>
          <a
            className="text-gray-600 block px-4 py-1 text-xs hover:text-blue-500"
            role="menuitem"
            id="menu-item-0"
            rel="noopener noreferrer"
          >
            {own.attributes.name}
          </a>
        </Link>
      );
    }
  };

  return (
    <div ref={wrapperRef}>
      {data.attributes.childrens?.data?.length ? (
        <div className="relative inline-block text-left">
          <div className="">
            <button
              type="button"
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-gray-90 hover:bg-gray-50"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {data.attributes.name}
              <svg
                className="-mr-1 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          {isShow ? (
            <div
              className="absolute right-0 z-40 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div className="py-1" role="none">
                {data.attributes.childrens?.data?.length > 0 &&
                  data.attributes.childrens.data.map((child) => {
                    return <MenuItem own={child} parent={data} />;
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Link href={`/${data.attributes.link}`} key={data.id}>
          <a rel="noopener noreferrer">
            <div className="relative inline-block text-left">
              <div className="">
                <button
                  type="button"
                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-gray-90 hover:bg-gray-50"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  {data.attributes.name}
                </button>
              </div>
            </div>
          </a>
        </Link>
      )}
    </div>
  );
};

export default DropdownItem;
